
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
    reactive,
  } from 'vue';
  import { useRouter } from 'vue-router';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import RecepitVoucher from '@/components/Reports/RecepitVoucher.vue';
  import CustomerServiceFilter from '@/components/dropdown/CustomerServiceFilter.vue';

  export default defineComponent({
    name: 'customer-service-statment-listing',
    components: {
      Datatable,
      RecepitVoucher,
      CustomerServiceFilter,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();
      const loading = ref(false);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(
          translate('financeCustomerServicePaymentListing'),
          [translate('finance')]
        );
        loading.value = true;
        await store.dispatch(Actions.GET_FINANCE_CUSTOMER_SERVICE_STATMENT);
        loading.value = false;
        await store.dispatch(Actions.GET_ALL_FINANCE_CUSTOMER_SERVICE);
        await store.dispatch(Actions.GET_ALL_CUSTOMERS);
      });

      onBeforeUnmount(() => {
        store.dispatch(Actions.RESET_FINANCE_CUSTOMER_SERVICE_STATMENT_STORE);
        store.dispatch(Actions.RESET_FINANCE_INVOICE_STORE);
      });

      const tableHeader = ref([
        {
          name: translate('customer Name'),
          key: 'customerName',
          sortable: true,
        },
        {
          name: translate('date'),
          key: 'date',
          sortable: true,
        },
        {
          name: translate('service Type'),
          key: 'serviceType',
          sortable: true,
        },

        {
          name: translate('phone Number'),
          key: 'customerPhoneNumber',
          sortable: true,
        },
        {
          name: translate('service Fees'),
          key: 'priceBeforeDiscount',
          sortable: true,
        },
        {
          name: translate('discount'),
          key: 'discount',
          sortable: true,
        },
        {
          name: translate('fees after discount'),
          key: 'amount',
          sortable: true,
        },
        {
          name: translate('status'),
          key: 'status',
          sortable: true,
        },

        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);
      const excelHeader = reactive({
        Customer: 'customerName',
        Date: 'date',
        'Service Type': 'serviceType',
        'Phone Number': 'customerPhoneNumber',
        'Service Fees': 'priceBeforeDiscount',
        Discount: 'discount',
        'Fees After Discount': 'amount',
        status: 'status',
      });

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const searchService = async (query) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_CUSTOMER_SERVICE_FILTER, query);
        await store.dispatch(
          Actions.UPDATE_FINANCE_CUSTOMER_SERVICE_STATMENT_CURRENT_PAGE,
          1
        );
        await store.dispatch(Actions.GET_FINANCE_CUSTOMER_SERVICE_STATMENT);
        await store.dispatch(
          Actions.GET_ALL_FINANCE_CUSTOMER_SERVICE,
          query.value
        );

        loading.value = false;
      };
      const onReset = async (query) => {
        await store.dispatch(Actions.UPDATE_CUSTOMER_SERVICE_FILTER, query);
        loading.value = true;
        await store.dispatch(
          Actions.UPDATE_FINANCE_CUSTOMER_SERVICE_STATMENT_CURRENT_PAGE,
          1
        );
        await store.dispatch(Actions.GET_FINANCE_CUSTOMER_SERVICE_STATMENT);
        await store.dispatch(Actions.GET_ALL_FINANCE_CUSTOMER_SERVICE);

        loading.value = false;
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(
          Actions.UPDATE_FINANCE_CUSTOMER_SERVICE_STATMENT_CURRENT_PAGE,
          val
        );
        await store.dispatch(Actions.GET_FINANCE_CUSTOMER_SERVICE_STATMENT);
        loading.value = false;
      };

      const excelData = computed(() => {
        return store.getters.allCustomerServiceStatmentsList?.map(
          (customerCredit) => {
            const status = customerCredit.status ? 'Paid' : 'Not Paid';
            return {
              ...customerCredit,
              status: status,
            };
          }
        );
      });

      return {
        tableData: computed(() => store.getters.customerServiceStatmentsList),
        tableHeader,
        excelData,
        excelHeader,
        translate,
        totalItems: computed(
          () => store.getters.customerServiceStatmentsListCount
        ),
        pageChanged,
        goTo,
        loading,
        can,
        searchService,
        onReset,
      };
    },
  });
